import {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
            , info: null
        };
    }

 
    async componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({
            error
            , info
        });
    }
 
    render() {
        if (this.state.error) {
            if (process.env.NODE_ENV == 'production') {
                return (
                    <div style={{color: 'red'}}>Error</div>
                );
            }
        }
 
        return this.props.children;
    }
}
 
export default ErrorBoundary;