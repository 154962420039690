import {useState, useLayoutEffect} from 'react';
import {throttle} from 'lodash';

export const useAsyncModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [promiseInfo, setPromiseInfo] = useState({});
    const [modalProps, setModalProps] = useState({});

    const show = (_modalProps = {}) => new Promise((resolve, reject) => {
        setPromiseInfo({resolve, reject});
        setModalProps(_modalProps);
        setIsOpen(true);
    });

    const handleClose = () => {
        setIsOpen(false);
        setModalProps({});
    }

    return {
        isOpen
        , handleClose
        , show
        , resolve: promiseInfo.resolve
        , reject: promiseInfo.reject
        , ...modalProps
    };
};

export const useWindowSize = (delay = 300) => {
    const [size, setSize] = useState([0, 0]);

    const updateSize = throttle(() => setSize([window.innerWidth, window.innerHeight]), delay);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};