import {hashCode} from './string-utils';
import qs from 'qs';
import UAParser from 'ua-parser-js';

export const OS = (() => {
    const parser = new UAParser();
    return parser.getOS();
})();

export const getDeviceDimensions = () => {
    if (OS == 'Android') {
        return {
            width: window.screen.availWidth
            , height: window.screen.availHeight
        };
    } else {
        return {
            width: window.innerWidth
            , height: window.innerHeight
        };
    }
};

export function isCordova() {
    let search = document.location.search;
    if (search == '') return false;
    search = search.substr(1);  // remove '?'
    const query = qs.parse(search);
    return navigator.userAgent.includes(' env:cordova') || query.cordova == 'true';
}

export async function deviceReady() {
    return isCordova()
        ? new Promise(resolve => {
            document.addEventListener('deviceready', resolve, false);
        })
        : true;
}

export const getFingerprint = () => {
    let text = navigator.userAgent.replace(/[0-9.]/g, '');
    return hashCode(text);
};