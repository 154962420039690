import {css} from 'styled-components';

/*
    size    px
    ----    --
    0       0
    1       2
    2       4
    3       8
    4       16
    5       32
    6       64
*/
export function spacing(size) {
    if (typeof size != 'number' || size < 0 || size != ~~size) {
        throw new Error(`size must be a non-negative integer, received [${size}] (${typeof size})`);
    }
    return size == 0 ? 0 : Math.pow(2, size);
}

export const m = (size) => css`
    margin: ${spacing(size)}px;
`;

export const my = (size) => css`
    margin-top: ${spacing(size)}px;
    margin-bottom: ${spacing(size)}px;
`;

export const mx = (size) => css`
    margin-right: ${spacing(size)}px;
    margin-left: ${spacing(size)}px;
`;

export const mt = (size) => css`
    margin-top: ${spacing(size)}px;
`;

export const mr = (size) => css`
    margin-right: ${spacing(size)}px;
`;

export const mb = (size) => css`
    margin-bottom: ${spacing(size)}px;
`;

export const ml = (size) => css`
    margin-left: ${spacing(size)}px;
`;

export const p = (size) => css`
    padding: ${spacing(size)}px;
`;

export const py = (size) => css`
    padding-top: ${spacing(size)}px;
    padding-bottom: ${spacing(size)}px;
`;

export const px = (size) => css`
    padding-right: ${spacing(size)}px;
    padding-left: ${spacing(size)}px;
`;

export const pt = (size) => css`
    padding-top: ${spacing(size)}px;
`;

export const pr = (size) => css`
    padding-right: ${spacing(size)}px;
`;

export const pb = (size) => css`
    padding-bottom: ${spacing(size)}px;
`;

export const pl = (size) => css`
    padding-left: ${spacing(size)}px;
`;

export const t = (size) => css`
    top: ${spacing(size)}px;
`;

export const r = (size) => css`
    right: ${spacing(size)}px;
`;

export const b = (size) => css`
    bottom: ${spacing(size)}px;
`;

export const l = (size) => css`
    left: ${spacing(size)}px;
`;

export const lh = (size) => css`
    line-height: ${spacing(size)}px;
`;

export const s = (size) => css`
    width: ${spacing(size)}px;
    height: ${spacing(size)}px;
`;

export const w = (size) => css`
    width: ${spacing(size)}px;
`;

export const h = (size) => css`
    height: ${spacing(size)}px;
`;
