import '~/style/main.scss';
import ReactDOM from 'react-dom';
import init from './init';

(async () => {
    const rootEl = document.getElementById('root');
    if (rootEl == null) {
        throw new Error('No root element');
    }
    
    const success = await init(rootEl);
    if (!success) return;
    
    const render = () => {
        const App = require('./components/App').default;
        ReactDOM.render(<App />, rootEl);
    };
    render();
    
    if (process.env.NODE_ENV == 'development' && module.hot) {
        module.hot.accept('./components/App', render);
    }
})();
