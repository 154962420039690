import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {ft} from '~/style/typography';
import {useWindowSize} from '~/services/hooks';
import {getFingerprint} from '~/lib/device';
import axios from 'axios';
import Cookies from 'js-cookie';

const KeypadLayout = styled.div`
    .btn-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: .5rem;
    }

    button {
        background-color: #fff;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
        ${ft(4)}
        border-radius: 2vmin;
        font-weight: 400;
        text-transform: inherit;

        &.clear {
            ${ft(2)}
            color: var(--alert-color);
        }

        &.enter {
            ${ft(2)}
            color: white;
            background-color: var(--action-color);
        }
    }

    .device-input {
        border: 2px solid var(--action-color);
        text-align: center;
        color: #777;
        ${ft(3)}
        margin-bottom: .5rem;
    }

    .device-error {
        text-align: center;
        color: var(--alert-color);
        margin-bottom: .5rem;
        ${ft(2)}
    }
`;

const Keypad = () => {
    useWindowSize();

    const [errorState, setError] = useState(false);
    const [inputState, setInput] = useState('');
    const [checking, setChecking] = useState(false);

    const inputRef = useRef();
    inputRef.current = inputState;

    useEffect(() => {
        document.body.addEventListener('keyup', onKeyUp);
        return () => document.body.removeEventListener('keyup', onKeyUp);
    }, []);

    async function onKeyUp(e) {
        const {which} = e;
        switch (which) {
            case 13:    // enter
                onEnter();
                break;
            case 46:    // delete
                onClear();
                break;
            case 8:     // backspace
                setInput(inputRef.current.substring(0, inputRef.current.length - 1));
                setError(false);
                break;
            case 86:    // v
                if (e.ctrlKey) {
                    // paste
                    const result = await navigator.permissions.query({name: 'clipboard-read'});
                    if (result?.state == 'granted' || result?.state == 'prompt') {
                        const deviceId = (await navigator.clipboard.readText()).trim().toLowerCase();
                        const isValid = clientValidation(deviceId);
                        if (isValid !== true) {
                            setError(isValid);
                            setInput('');
                            return;
                        } else {
                            setInput(deviceId);
                            setError(false);
                        }
                    }
                }
                break;
            default:
                if (which >= 48 && which <= 57) {
                    // number row
                    onDigit(String(which - 48));
                } else if (which >= 96 && which <= 105) {
                    // keypad
                    onDigit(String(which - 96));
                } else if (which >= 65 && which <= 70) {
                    onDigit(String.fromCharCode(which + 32));
                }
        }
    }

    function onDigit(digit, e) {
        e?.currentTarget.blur();
        if (checking) return;
        setInput(`${inputRef.current}${digit}`);
        setError(false);
    }

    function onClear(e) {
        e?.currentTarget.blur();
        if (checking) return;
        setInput('');
        setError(false);
    }

    async function onEnter(e) {
        e?.currentTarget.blur();
        if (checking) return;
        
        const deviceId = inputRef.current.trim().toLowerCase();
        const isValid = clientValidation(deviceId);
        if (isValid !== true) {
            setError(isValid);
            setInput('');
            return;
        }

        setChecking(true);

        let deviceInfo = {};
        if (window.device) {
            const {device} = window;
            const props = Object.keys(device);
            props.forEach(prop => deviceInfo[prop] = device[prop]);
        }

        try {
            const {data} = await axios.post('/', {
                deviceId
                , fingerprint: getFingerprint()
                , deviceInfo
            });

            if (data.error) {
                const {error} = data;
                switch (error.errorCode) {
                    case 'MISSING_DEVICE_ID':
                    case 'MISSING_FINGERPRINT':
                    case 'MISSING_DEVICEINFO':
                        setError(errorToStr('Bad Request', error));
                        break;
                    case 'GET_DEVICE_REGISTRY':
                        setError(errorToStr('Unable to check device registry', error));
                        break;
                    case 'LOCKED_DEVICE':
                        setError('Device is locked');
                        break;
                    case 'GET_DEVICE_METADATA':
                        setError(errorToStr('Unable to get device metadata', error));
                        break;
                    case 'NO_CONFIGURED_VERSION':
                        setError('Device is not configured with a software version');
                        break;
                    case 'PUT_DEVICE_METADATA':
                        setError(errorToStr('Unable to set device metadata', error));
                        break;
                    case 'PUT_DEVICE_REGISTRY':
                        setError(errorToStr('Unable to update device registry', error));
                        break;
                    case 'GET_USER_DOCUMENT':
                        setError(errorToStr('Unable to retrieve account credentials', error));
                        break;
                    default:
                        setError(errorToStr('Unexpected error', error));
                }
                setChecking(false);
                return;
            }
            
            const {connection} = data;
            localStorage.connection = connection;

            const connectionCookie = Cookies.get('connection');
            if (!connectionCookie) {
                Cookies.set(
                    'connection'
                    , connection
                    , {expires: 365 * 20}
                );
            }

            document.location.reload();
        } catch (e) {
            if (e.response) {
                setError(`Server Error: ${e.response.status} ${e.response.statusText}`);
            } else if (e.request) {
                setError(`Network Error: ${e.errno}`);
            } else {
                setError(`Error: ${e.message}`);
            }
        }

        setChecking(false);
    }

    function errorToStr(msg, error) {
        return `${msg} ${JSON.stringify(error)}`;
    }

    const MIN_LENGTH = 8;
    const MAX_LENGTH = 8;
    function clientValidation(deviceId) {
        if (deviceId == '') {
            return 'Device ID is required';
        } else if (!deviceId.match(/^[0-9a-f]*$/)) {
            return 'Device ID is not correctly formatted';
        } else if (deviceId.length < MIN_LENGTH) {
            return `Device ID must be ${MIN_LENGTH} characters long`;
        } else if (deviceId.length > MAX_LENGTH) {
            return `Device ID cannot exceed ${MAX_LENGTH} characters`;
        }
        return true;
    }

    return (<KeypadLayout className="keypad">
        <div className="device-input">
            {inputState.length > 0
                ? inputState.split('').map((n, idx) => (
                    <span key={idx} className="input-digit">{n}</span>
                ))
                : <i>Enter Device ID</i>
            }
        </div>

        <div className="device-error">
            {errorState
                ? <div>{errorState}</div>
                : <div>&nbsp;</div>
            }
        </div>
        
        <div className="btn-grid">
            <Button onClick={e => onDigit('a', e)} disabled={checking}>a</Button>
            <Button onClick={e => onDigit('b', e)} disabled={checking}>b</Button>
            <Button onClick={e => onDigit('c', e)} disabled={checking}>c</Button>
            <Button onClick={e => onDigit('d', e)} disabled={checking}>d</Button>
            <Button onClick={e => onDigit('e', e)} disabled={checking}>e</Button>
            <Button onClick={e => onDigit('f', e)} disabled={checking}>f</Button>
            <Button onClick={e => onDigit('1', e)} disabled={checking}>1</Button>
            <Button onClick={e => onDigit('2', e)} disabled={checking}>2</Button>
            <Button onClick={e => onDigit('3', e)} disabled={checking}>3</Button>
            <Button onClick={e => onDigit('4', e)} disabled={checking}>4</Button>
            <Button onClick={e => onDigit('5', e)} disabled={checking}>5</Button>
            <Button onClick={e => onDigit('6', e)} disabled={checking}>6</Button>
            <Button onClick={e => onDigit('7', e)} disabled={checking}>7</Button>
            <Button onClick={e => onDigit('8', e)} disabled={checking}>8</Button>
            <Button onClick={e => onDigit('9', e)} disabled={checking}>9</Button>
            <Button onClick={onClear} className="clear" disabled={checking}>Clear</Button>
            <Button onClick={e => onDigit('0', e)} disabled={checking}>0</Button>
            <Button onClick={onEnter} className="enter" disabled={checking || inputRef.current.length == 0}>Enter</Button>
        </div>
    </KeypadLayout>);
};

export default Keypad;