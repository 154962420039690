var api = require("!../../../node_modules/.pnpm/style-loader@1.2.1_webpack@4.44.2/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/.pnpm/css-loader@4.3.0_webpack@4.44.2/node_modules/css-loader/dist/cjs.js!../../../node_modules/.pnpm/sass-loader@10.0.2_node-sass@4.14.1+webpack@4.44.2/node_modules/sass-loader/dist/cjs.js!./main.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};