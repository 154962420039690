import styled from 'styled-components';
import Keypad from './Keypad';
import {ft} from '~/style/typography';

const RegisterLayout = styled.div`
    font-family: Arial, Helvetica, sans-serif;

    display: grid;
    height: 100%;
    > * {
        margin: 1rem;
    }

    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr auto 1fr;
    grid-template-areas:
        ".      .       ."
        ".      keypad  ."
        "help   .       .";
    
    @media screen and (orientation:landscape) {
        grid-template-columns: repeat(3, 1fr);
    }

    .keypad {
        grid-area: keypad;
        z-index: 10;
    }

    .help {
        grid-area: help;
        position: absolute;
        bottom: 0;
        span {
            font-weight: 700;
        }
        ${ft(1)}
        z-index: 0;
    }
`;

const Register = () => {
    return (
        <RegisterLayout>
            <Keypad />

            <div className="help">
                <span>Technical Support:</span>
                <br/>800-575-4042
                <br/><a href="//www.vanillaware.com/support">www.vanillaware.com/support</a>
            </div>
        </RegisterLayout>
    );
};

export default Register;