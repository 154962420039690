import {isCordova} from './device';

export const loadScript = filename => {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = filename;
    head.appendChild(script);
};

const loadEnvScripts = () => {
    if (isCordova()) {
        loadScript('cordova.js');
    }
};
export default loadEnvScripts;