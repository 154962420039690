import styled, {css} from 'styled-components';
import * as spacing from './spacing';
import {getDeviceDimensions} from '~/lib/device';

const TYPE_SCALE = Math.pow(2, 1 / 4);  // very close to golden ratio at n=3
const MIN_SCREEN_WIDTH = 350;
const MAX_SCREEN_WIDTH = 1080;
const MIN_FONT_PX = 12;
const MAX_FONT_PX = 20;
const LINE_HEIGHT_RATIO = 1.2;          // ratio of line-height to font
const LINE_HEIGHT_BASE = 4;             // snap line-height to 4px grid

/*
    This function combines responsive typography and typographic scale.
    Repsonsive Typography:
        MIN_FONT_PX is the default font size (body) at the MIN_SCREEN_WIDTH.  Any screen widths less than
        MIN_SCREEN_WIDTH will use MIN_FONT_PX.
        MAX_FONT_PX is the equivalent default font size at MAX_SCREEN_WIDTH.  Any screen widths greater
        than MAX_SCREEN_WIDTH will use MAX_FONT_PX.  Any screen widths in between will interpolate the
        font size.
    Typographic scale:
        TYPE_SCALE is the scaling factor between font sizes.  If Math.pow(r, 1/n), it would take n steps
        to increase the font size by a factor of r.  The input size parameter defaults to 0 and
        corresponds to the default font size defined above.  If r=2 and n=4, then a font size of 4 would
        be twice the size of 0, 8 is twice the size of 4, 12 is twice the size of 8, etc.  Negative numbers
        are fine and work in the same manner: -4 is half the size of 0, -8 is half of -4, etc.
        Fractional sizes will work, but that defeats the purpose of using a typographic scale to
        achieve font sizes that work well together.

        r=2, n=4:
        size    scale
        ----    -----
        -4      0.5000
        -3      0.5946
        -2      0.7071
        -1      0.8409
        0       1.0000
        1       1.1892
        2       1.4142
        3       1.6818
        4       2.0000
        5       2.3784
        6       2.8284
        7       3.3636
        8       4.0000
*/
function fontsize(size, baseFontSize) {
    return Math.pow(TYPE_SCALE, size) * baseFontSize;
}

function lineheight(size, baseFontSize) {
    return Math.ceil(fontsize(size, baseFontSize) * LINE_HEIGHT_RATIO / LINE_HEIGHT_BASE) * LINE_HEIGHT_BASE;
}

export const ft = size => () => {
    const {width, height} = getDeviceDimensions();
    const vmin = Math.min(width, height);
    if (vmin < MIN_SCREEN_WIDTH) {
        return css`
            font-size: ${fontsize(size, MIN_FONT_PX)}px;
            line-height: ${lineheight(size, MIN_FONT_PX)}px;
        `;
    } else if (vmin < MAX_SCREEN_WIDTH) {
        const a = (vmin - MIN_SCREEN_WIDTH) / (MAX_SCREEN_WIDTH - MIN_SCREEN_WIDTH);
        const baseFontSize = MIN_FONT_PX + a * (MAX_FONT_PX - MIN_FONT_PX);
        return css`
            font-size: ${fontsize(size, baseFontSize)}px;
            line-height: ${lineheight(size, baseFontSize)}px;
        `;
    } else {
        return css`
            font-size: ${fontsize(size, MAX_FONT_PX)}px;
            line-height: ${lineheight(size, MAX_FONT_PX)}px;
        `;
    }
};

const HeadingStyle = styled.div`
    ${({size}) => ft(size)}
    ${({my}) => spacing.my(my)}
`;
export const H1 = ({children, size = 6, my = 4, ...props}) => (
    <HeadingStyle as="h1" size={size} my={my} {...props}>{children}</HeadingStyle>
);
export const H2 = ({children, size = 5, my = 4, ...props}) => (
    <HeadingStyle as="h2" size={size} my={my} {...props}>{children}</HeadingStyle>
);
export const H3 = ({children, size = 4, my = 4, ...props}) => (
    <HeadingStyle as="h3" size={size} my={my} {...props}>{children}</HeadingStyle>
);
export const H4 = ({children, size = 3, my = 4, ...props}) => (
    <HeadingStyle as="h4" size={size} my={my} {...props}>{children}</HeadingStyle>
);
export const H5 = ({children, size = 2, my = 4, ...props}) => (
    <HeadingStyle as="h5" size={size} my={my} {...props}>{children}</HeadingStyle>
);
export const H6 = ({children, size = 1, my = 4, ...props}) => (
    <HeadingStyle as="h6" size={size} my={my} {...props}>{children}</HeadingStyle>
);
